<!--
 * @Description:
 * @Author: hanyu
 * @Date: 2021-03-24 15:56:35
 * @LastEditTime: 2021-03-26 11:05:29
 * @LastEditors: hanyu
-->
<template>
  <div class="login-container">
    <nuxt-link to="/" class="logo">
      <img :src="`/images/index/index-logo.png`" alt />
    </nuxt-link>
    <p class="title">
      云南省{{$enum.getDescByValue('APP_ENV_TYPE_ENUM',type)}}继续教育施教机构
    </p>
    <div class="login-content">
      <AccountContainer ref="accountContainer" :show-wx-login-type="true" @success="loginSuccess"></AccountContainer>
    </div>
  </div>
</template>

<script>
import AccountContainer from '@/components/account/components/account-container';
import { accountApi } from '@/api/account';
export default {
  layout: 'empty',
  name: 'Login',
  components: {
    AccountContainer
  },
  data () {
    return {
    };
  },
  computed: {
    userInfo () {
      return this.$store.getters['user/userInfo'];
    },
    type () {
      return process.env.TYPE;
    }
  },
  mounted () {
    this.$refs.accountContainer.initListenEnterKeyDown();
    this.getUserDetail();
  },
  methods: {
    loginSuccess () {
      if (this.$route.query.redirect) {
        window.location.replace(decodeURIComponent(this.$route.query.redirect));
      } else {
        // 默认首页
        this.$router.replace({
          path: '/'
        });
      }
    },
    // 获取用户详情 校验token是否失效
    async getUserDetail () {
      try {
        await accountApi.getUserDetail();
        if (!this.lodash.isEmpty(this.userInfo)) {
          this.loginSuccess();
        }
      } catch (e) {
        this.$smartSentry.captureException(e);
        console.error(e);
      } finally {
        // 拦截器里会弹出登录弹框 在登录页就不需要了 直接关闭弹框
        this.$store.commit('user/changeAccountModal', { isShow: false });
      }
    }
  },
  head () {
    return {
      title: '云南省药师继续教育平台官网登录|账号注册',
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: '人民医学,云南省药师继续教育平台官网,云南省药师继续教育平台登录,云南省药师继续教育平台注册,云南省药师继续教育平台教育网,云南省药师继续教育平台账号注册,云南省药师继续教育平台用户登录'
        },
        {
          hid: 'description',
          name: 'description',
          content: '欢迎大家登录或注册云南省药师继续教育平台账号，人民医学教育网上线医学教育课程有医师资格、健康管理师、药师资格、卫生资格、主治医师、药学职称、医学专升本、小儿推拿等，更多课程敬请期待。'
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
