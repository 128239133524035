// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/login/login-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-container[data-v-d6151a1e]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%;height:100vh;line-height:1;position:relative;width:100%}.login-container .title[data-v-d6151a1e]{color:#fff;font-size:60px;left:230px;line-height:1.2;position:absolute;top:398px}.login-container .logo[data-v-d6151a1e]{left:89px;position:absolute;top:56px}.login-container .logo[data-v-d6151a1e],.login-container .logo>img[data-v-d6151a1e]{height:63px;width:377px}.login-container .login-content[data-v-d6151a1e]{background:hsla(0,0%,100%,.9)!important;border-radius:10px;height:510px;padding:40px;position:absolute;right:12%;top:26%;width:430px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
